@font-face {
	font-family: 'Utily';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url('../fonts/Utily-Sans/Utily-Regular/Utily-Regular.woff') format('woff');
}
@font-face {
	font-family: 'Utily';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: url('../fonts/Utily-Sans/Utily-Medium/Utily-Medium.woff');
}
@font-face {
	font-family: 'Utily';
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	src: url('../fonts/Utily-Sans/Utily-Bold/Utily-Bold.woff') format('woff');
}
@font-face {
	font-family: 'Utily';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url('../fonts/Utily-Sans/Utily-Black/Utily-Black.woff') format('woff');
}
@font-face {
	font-family: 'Kiperman';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url('../fonts/Kiperman/Kiperman-Regular/Kiperman-Regular.woff') format('woff');
}
