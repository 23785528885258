@import "./fonts.css";
@import "./normalize.css";
@import "@glidejs/glide/dist/css/glide.core.min.css";
@import "aos/dist/aos.css";

html * {
	box-sizing: border-box;
}

body {
	/*Reset*/
	margin:0;
	padding:0;

	/*Base*/
	color: #000;
	background-color: #fff;
	line-height: 1.5;

	/*Typography*/
	font-family: "Utily", "Arial", sans-serif;
	font-size: calc(1rem + 0.4vw);
}
/* Reset */
html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p,
article, aside,
footer, header, nav {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	vertical-align: middle;
	background:transparent;
}
*, ::before, ::after {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Accessibility */
/* Screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}
#content[tabindex="-1"]:focus {
	outline: 0;
}

/*--------------------------------------------------------------
# Standards
--------------------------------------------------------------*/
a {
	text-decoration: none;
	color: #000;
}
img {
	max-width: 100%;
}
.flex {
	display: flex;
	flex-wrap: wrap;
}
.wrapper {
	padding: 15px;
	max-width: 1440px;
	margin: 0 auto;
}
.wrapper-fluid {
	width: 100%;
}
.testimonials-wrapper {
	padding: 15px 0 15px 15px;
	overflow-x: hidden;
}
@media screen and (min-width:768px) {
	.wrapper {
		padding: 30px;
	}
	.testimonials-wrapper {
		padding: 30px 0 30px 30px;
	}
}
@media screen and (min-width: 992px) {
	.testimonials-wrapper {
		padding: 0 0 0 8%;
	}
	.flex {
		display: flex;
		flex-wrap: nowrap;
	}
}

@media screen and (max-width: 600px) {
	.hide-mobile {
		display: none;
	}
}

@media screen and (max-width: 1240px) {
	.hide-tablet {
		display: none;
	}
}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
h1 {
	font-size: calc(3rem + 4vw);
	font-weight: 700;
	line-height: 1;
	margin-bottom: 40px;

	@media screen and (min-width: 2000px) {
		font-size: calc(3rem + 3vw);
	}
}
h2 {
	font-size: calc(2rem + 1vw);
	margin-bottom: 20px;
}
h4 {
	font-size: calc(1.5rem + 1vw);
	letter-spacing: -1px;
}
p {
	margin-bottom: 15px;
}
@media screen and (min-width: 1400px) {
	body {
		font-size: 1.5rem;
	}
}

main {
	width: 100%;
}
/*--------------------------------------------------------------
# Backgrounds
--------------------------------------------------------------*/
.bg-wrap {
	position: relative;
	width: 100%;
}
.background-waves {
	transform: translateY(-100%);
	position: relative;

}
.background-wave-blue {
	position: absolute;
	bottom: 0;
	z-index: 1;
	background-size: cover;
	width: 100%;
	height: auto;
	min-width: 100vw;
}
.background-wave-green {
	position: absolute;
	bottom: 6vh;
	z-index: 1;
	background-size: cover;
	width: 100%;
	height: auto;
	min-width: 100vw;
}
.footer-wave {
	position: absolute;
	top: 0;
	background-image: url("../img/backgrounds/footer-wave.svg");
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 300px;
}

/* Parallax */
.parallax{
	position: relative;
	padding-top: 64px;
}

section header.parallax-header {
	overflow: hidden;
	position: relative;
	min-height: 600px;
}

@media (min-width: 800px) {
	section header.parallax-header {
		min-height: 70vh;
	}
}

.parallax-outline-text {
	position: absolute;
}

.parallax-outline-text p {
	pointer-events: none;
	text-align: center;
	white-space: nowrap;
	overflow-x:hidden;
	font-size: 50vw;
	font-weight: 800;
	opacity: 0.5;
	color: #A7D9EF;
	-webkit-text-fill-color: #E5F6FC;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #A7D9EF;
}
.parallax-outline-text p.marquee3k__copy {
	padding-right: 10vw;
}
.parallax header .wave {
	pointer-events: none;
	position: absolute;
	z-index: 1;
	width: 1920px;
	height: auto;
	min-width: 100vw;
	object-fit: cover;
}
.parallax header .wave.top {
	top: -1px;
	z-index: 1;
}
.parallax header .wave.bottom {
	bottom: 0px;
	z-index: 3;
}
.parallax header .bubble {
	pointer-events: none;
	position: absolute;
	z-index: 2;
}
.parallax header .bubble.left {
	left: 32px;
}
.parallax header .bubble.right {
	right: 32px;
}
.parallax .cta-wrap {
	margin-top: 3rem;
	padding-bottom: 0;
}

/* Intro Bubbles */
.bubble-one {
	position: absolute;
	top: 0;
	left: 27%;
	background-image: url(../img/backgrounds/intro-bubble-1.svg);
	width: 50%;
	height: 180px;
	background-repeat: no-repeat;
	background-size: contain;
	z-index: -1;
}
.bubble-two {
	position: absolute;
	top: 0;
	left: 42%;
	background-image: url(../img/backgrounds/intro-bubble-2.svg);
	width: 50%;
	height: 300px;
	background-repeat: no-repeat;
	background-size: contain;
	z-index: -1;
}
.bubble-three {
	position: absolute;
	top: 25%;
	left: 47%;
	background-image: url(../img/backgrounds/intro-bubble-3.svg);
	width: 50%;
	height: 700px;
	background-repeat: no-repeat;
	background-size: contain;
	z-index: -1;
}
.bubble-four {
	position: absolute;
	top: 100%;
	left: 70%;
	background-image: url(../img/backgrounds/intro-bubble-4.svg);
	width: 20%;
	height: 300px;
	background-repeat: no-repeat;
	background-size: contain;
	z-index: -1;
}

/*--------------------------------------------------------------
# Intro Animation
--------------------------------------------------------------*/
 .slide-down {
	-webkit-animation: slide-down 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
	animation: slide-down 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
 }
@keyframes slide-down {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
.fade-in {
	-webkit-animation: fade-in 0.5s ease-in-out 0s forwards;
	animation: fade-in 0.5s ease-in-out 0s forwards;
	opacity: 0;
}

@keyframes fade-in {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
.intro-fade-up {
	opacity: 0;
	-webkit-animation: intro-fade-up 1s ease-in-out 0s forwards;
	animation: intro-fade-up 1s ease-in-out 0s forwards;
}
@keyframes intro-fade-up {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}
.slide-diagonal {
	-webkit-animation: slide-diagonal 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-diagonal 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes slide-diagonal {
	from {
		-webkit-transform: translate3d(100%, 100%, 0);
		transform: translate3d(100%, 100%, 0);
	}
	to {
		-webkit-transform: none;
		transform: none;
	}
}

/* Animation Delays */
/* 2	Bubble 2*/
.bubble-two {
	animation-delay: 0.2s;
}
/* 3 Bubble 3 */
.bubble-three {
	animation-delay: 0.4s;
}
/* 4	Bubble 4 and Waves*/
.bubble-four, .background-wave-green	{
	animation-delay: 0.6s;
}
/* 5 Navbar*/
.background-wave-blue, .slide-down {
	animation-delay: 0.7s;
}
/* 6 Text left*/
.intro-text-wrap h1.fade-in, .fade-in {
	animation-delay: 0.8s;
}
/* 7 Text left under*/
.intro-fade-up {
	animation-delay: 0.9s;
}

/*--------------------------------------------------------------
# Colors
--------------------------------------------------------------*/
.bg-lightblue {
	background-color: #E5F6FC;
}
.bg-lightgreen {
	background-color: #F6FAE9;
}

/*--------------------------------------------------------------
# Call to action
--------------------------------------------------------------*/
.cta {
	a {
		display: flex;
		align-items: center;
		font-size: 1rem;
		font-weight: 600;
		padding: 8px 15px;
		color: #93B439;
		border: 1px solid #93B439;
		background-color: #F6FAE9;
		border-radius: 4px;
		transition: all .2s ease-in-out;

		@media screen and (min-width: 768px) {
			padding: 10px 16px;
		}

		svg {
			margin-right: .5rem;
		}

		&:hover {
			color: #111;
		}
	}

	&.cta-primary {
		a {
			&:hover {
				background-color: #E8F1C7;
			}
		}
	}

	&.cta-secondary {
		a {
			background-color: transparent;
			border: 1px solid transparent;
		}
	}
}

.cta-wrap {
	display: flex;

	@media screen and (min-width: 768px) {
		.cta + .cta {
			padding-left: 30px;
		}
	}
}

.numbers-grid-wrap {
	.cta {
		margin: 0 auto;

		a {
			padding: 15px 30px;

			&::after {
				width: 24px;
				height: 24px;
				margin-left: 1rem;
				content: url(../img/icons/arrow-right.svg);
			}
			svg {
				margin-right: 1rem;
			}
		}
	}
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.flex-left {
	flex-grow: 1;
}
.header {
	display: flex;
}

@media screen and (max-width: 767px) {
	.header .cta {
		display: none;
	}
}

@media screen and (min-width: 992px) {
	.header {
		margin-bottom: 50px;
	}
}

/*--------------------------------------------------------------
# Intro
--------------------------------------------------------------*/
.intro {
	min-height: 50vh;
}

.intro-text .cta-wrap {
	flex-wrap: wrap;
	justify-content: space-around;
	padding-top: 30px;
	padding-bottom: 30px;

	@media screen and (min-width: 768px) {
		justify-content: flex-start;
	}
}

.intro-text-wrap {
	z-index: 2;
	padding-bottom: 10px;
	position: relative;

	& > div {
		position: sticky;
		top: 0;
	}
}
.intro-text-wrap h2 {
	font-size: calc(1.5rem + 1vw);
	margin-bottom: 20px;
}
@media screen and (min-width:768px) {
	.intro-text-wrap {
		width: 70vw;
	}
}
@media screen and (min-width: 992px) {
	.intro-text-wrap {
		width: 50%;
		padding-right: 50px;
	}
	.intro-text-wrap h2 {
		font-size: 36px;
		margin-bottom: 30px;
	}
	.intro-text {
		width: 100%;
	}
	.sponsor-job-list-wrap {
			width: 50%;
	}
}
@media screen and (min-width: 1200px) {
	.intro-text {
		width: 80%;
	}
}
@media screen and (min-width:1800px) {
	.intro-text {
		width: 100%;
	}
	.intro-text-wrap {
		padding-right: 125px;
	}
}

/*--------------------------------------------------------------
# Job Overview
--------------------------------------------------------------*/
.sponsor-job-list-wrap {
	z-index: 2;
}
@media screen and (max-width: 991px) {
	.sponsor-job-list-wrap {
		width: 100%;
	}
}
.sponsor-job-list article {
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 4%;
	animation: intro-fade-up 1000ms ease-in-out both;
}
.sponsor-job-list article > a {
	display: block;
	background-color: #fff;
	border: 1px solid #F7FAFC;
	box-shadow: 0px 10px 10px rgba(0, 163, 223, 0.05), 0px 20px 25px rgba(0, 163, 223, 0.1);
	border-radius: 8px;
	padding: calc(10px + 2.5%);
	transition: all 240ms ease-in-out;
}
.sponsor-job-list article:hover > a {
	transform: translate3d(0,-2px,0);
	box-shadow: 0px 10px 10px rgba(0, 163, 223, 0.04), 0px 20px 50px rgba(0, 163, 223, 0.3);
}
.sponsor-job-list article + article {
	animation-delay: 100ms;
}
.sponsor-job-list article + article + article {
	animation-delay: 200ms;
}
.sponsor-job-list article + article + article + article {
	animation-delay: 300ms;
}
.sponsor-job-list article + article + article + article + article {
	animation-delay: 400ms;
}
.sponsor-job-list article + article + article + article + article + article {
	animation-delay: 500ms;
}
.sponsor-job-list article + article + article + article + article + article + article {
	animation-delay: 600ms;
}
.sponsor-job-list article + article + article + article + article + article + article + article {
	animation-delay: 700ms;
}
.sponsor-job-list article + article + article + article + article + article + article + article + article {
	animation-delay: 800ms;
}

.sponsor-job-list .sponsor-job-list-col:nth-child(2) {
	display: none;
}
.sponsor-job-list .job-title {
	margin-bottom: 5px;
	font-weight: 600;
	font-size: calc(1.2rem + 0.2vw);
	line-height: 1.2;
	letter-spacing: -0.02em;
	color: #00A3DF;
}
.sponsor-job-list article:hover .job-title {
	text-overflow: unset;
	white-space: unset;
}
.sponsor-job-list .company {
	font-size: 1rem;
	margin-bottom: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.sponsor-job-list .meta {
	display: flex;
	align-items: center;
}
.sponsor-job-list .location {
	color: rgba(0, 0, 0, 0.4);
	font-size: 1rem;
	margin-bottom: 5px;
}
.sponsor-job-list .location::before {
	content: url(../img/icons/map-pin.svg);
	width: 16px;
	height: 16px;
	float: left;
	margin: 3px 6px 0 0;
}
.sponsor-job-list .type {
	margin-left: 10px;
	color: rgba(0, 0, 0, 0.4);
	font-size: 1rem;
	margin-bottom: 5px;
}
.sponsor-job-list .type::before {
	content: url(../img/icons/clock.svg);
	width: 16px;
	height: 16px;
	float: left;
	margin: 3px 6px 0 0;
}
@media screen and (min-width: 768px) {
	.sponsor-job-list {
		display: flex;
		flex-wrap: wrap;
	}
	.sponsor-job-list article {
		width: 48%;
		margin-bottom: 0;
	}
	.sponsor-job-list article:nth-child(2n+1) {
		margin-right: 2%;
	}
	.sponsor-job-list article:nth-child(2n) {
		margin-left: 2%;
	}
	.sponsor-job-list article:nth-child(2n) > a {
		margin-top: 10%;
	}
	.sponsor-job-list article:nth-child(2n+1) > a {
		margin-bottom: 10%;
	}
	.sponsor-job-list .sponsor-job-list-col:nth-child(2) {
		display: flex;
		flex-direction: column;
		margin-top: 80px;
		margin-left: 50px;
	}
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.sponsor-job-list {
		flex-direction: column;
	}
	.sponsor-job-list article {
		width: 100%;
	}
	.sponsor-job-list article:nth-child(2n+1),
	.sponsor-job-list article:nth-child(2n) {
		margin-left: 0;
		margin-right: 0;
	}
	.sponsor-job-list article:nth-child(2n+1) > a,
	.sponsor-job-list article:nth-child(2n) > a {
		margin-top: 0;
		margin-bottom: 5%;
	}
}


/*--------------------------------------------------------------
# General Section Titles
--------------------------------------------------------------*/
.section-title  {
	position: relative;
	z-index: 2;
	padding: 30px 15px;

	p {
		@media screen and (min-width: 768px) {
			text-align: center
		}
	}
	h2 {
		color: #000;
		text-align: left;
		letter-spacing: -1px;
		@media screen and (min-width:768px) {
			color: #46A2DA;
			text-align: center;
		}
	}
}


/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
	z-index: 2;
	position: relative;
}


/* Slider */
.testimonials-slider {
	max-width: 100%;
	width: 100%;
	padding: 5px 0 64px 0;
}
.testimonial-slide {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 15px;
	transition: all 240ms ease-in-out;
	box-shadow: 0px 10px 10px rgba(0, 163, 223, 0.05), 0px 20px 25px rgba(0, 163, 223, 0.1);
	border-radius: 8px;
}

.testimonial-slide:hover {
	transform: translate3d(0,-2px,0);
	box-shadow: 0px 10px 10px rgba(0, 163, 223, 0.04), 0px 30px 50px rgba(0, 163, 223, 0.3);
}

.testimonial-slide .portrait {
		display: inline-block;
		font-size: 1rem;
		font-weight: 600;
		padding: .3rem .6rem;
		margin-bottom: 1rem;
		color: #93B439;
		border: 1px solid #93B439;
		background-color: #F6FAE9;
		border-radius: 4px;
		border: 1px solid transparent;
		transition: all .2s ease-in-out;

		&:hover {
			border: 1px solid #93B439;
		}
	}


.testimonial-slide .cite {
	font-family: "Kiperman", "Times", serif;
	color: #2D3748;
	flex-grow: 1;
}
.testimonial-slide .info-wrap {
	display: flex;
	align-items: flex-start;
	padding-top: 10px;
	border-top: 1px solid #E9F6FB;
}
.testimonial-slide img {
	height: 48px;
	width: 48px;
	border-radius: 50%;
}
.testimonial-slide .info {
	margin-left: 15px;
}
.testimonial-slide .name {
	font-weight: 600;
	color: #4A5568;
	margin-bottom: 10px;
}
.testimonial-slide .job-title {
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.0625em;
	text-transform: uppercase;
	color: #A0AEC0;
	margin-bottom: 0;
}
@media screen and (min-width: 992px) {
	.testimonials-slider {
		padding: 15px 0 60px 0;
	}
	.testimonial-slide {
		padding: 30px;
	}
	.testimonial-slide .cite {
		padding-bottom: 15px;
	}
	.testimonial-slide img {
		height: 80px;
		width: 80px;
		border-radius: 50%;
	}
	.testimonial-slide .info-wrap {
		padding-top: 35px;
		align-items: center;
		font-size: 0.825rem;
	}
}

/* Slider */
.glide__track {
    overflow: visible !important;
}

.glide__slides {
	overflow: visible !important;
}

.glide__slide {
	transition: all .2s ease;
}

.glide__slide--active {
	& + .glide__slide {
		opacity: 0.5;
		& + .glide__slide {
			opacity: 0.5;
			& + .glide__slide {
				opacity: 0.5;
			}
		}
	}
}

[data-glide-el="controls"] {
	position: absolute;
	right: 1rem;
	z-index: 2;
	top: 50%;

	@media screen and (min-width: 768px) {
		right: 3rem;
	}
}

button[data-glide-dir=">"] {
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Green / 100 */

	background: #F6FAE9;
	/* Green / 500 */

	border: 2px solid #A3C840;
	box-sizing: border-box;
	box-shadow: 5px 5px 30px rgba(0, 163, 223, 0.2), -5px -5px 30px rgba(255, 255, 255, 0.5), inset 5px 5px 100px rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	outline: none;

	& svg {
		position: relative;
		left: 2px;
		width: 16px;
		height: 16px;
	}

	@media (min-width: 768px) {
		width: 64px;
		height: 64px;
	}
}

/*--------------------------------------------------------------
# Weiterbildungen
--------------------------------------------------------------*/
.weiterbildungen {
	.flex {
		@media screen and (min-width: 768px) {
			justify-content: space-between;
		}
	}
	.sponsor-job-list-wrap {
		@media screen and (min-width: 768px) {
			width: 100%;
		}

		h2 {
			text-align: center;
		}
	}
	.sponsor-job-list {
		.meta {
			display: block;
			align-items: unset;

			.type {
				margin-left: 0;
			}
		}
	}
}
/*--------------------------------------------------------------
# Gründe Jobsuchende /Firmen
--------------------------------------------------------------*/
.numbers-grid-wrap {
	background: #E5F6FC;
	margin: 0 auto;
}
.numbers-grid-wrap p {
	margin: 0;
}

.reasons {
	position: relative;
	z-index: 10;
}

.reasons .cta {
	margin: 0 auto;
}

.reasons-title {
	position: absolute;
	top: 25%;
	left: 50%;
	z-index: 4;
}

.reasons-title h2 {
	color: #6CBDE5;
	text-align: center;
	letter-spacing: -1px;
	transform: translateX(-50%);
}
.reasons-title span {
	font-size: calc(3rem + 4vw);
	color: #46A2DA;
	letter-spacing: -0.015625em;
}
.numbers-grid {
	display: flex;
	margin: 0 auto;
	padding: 20px;
	list-style: none;
	max-width: 1000px;
	grid-template-columns: 1fr;
	background: #FFF;
	border-radius: 15px;
	z-index: 2;
	position: relative;
	box-shadow: 5px 5px 30px rgba(0, 163, 223, 0.2), -5px -5px 30px rgba(255, 255, 255, 0.5), inset 5px 5px 100px rgba(255, 255, 255, 0.5);
	overflow-x: scroll;
	scroll-snap-type: x proximity;
}

@media screen and (min-width: 400px) {
	.numbers-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
@media screen and (min-width: 800px) {
	.numbers-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.numbers-grid li {
	position: relative;
	padding-bottom: 100%;
	min-width: 100%;
	border: solid #E8F1C7;
	border-width: 0 1px 0 0;
}
@media screen and (max-width: 399px) {
	.numbers-grid li {
		width: 80%;
		min-width: 80%;
		padding-bottom: 80%;
	}
}
@media screen and (min-width: 400px) {
	.numbers-grid li {
		border-width: 0 1px 1px 0;
	}
}
@media screen and (min-width: 400px) and (max-width: 799px) {
	.numbers-grid li:nth-child(2n) {
		border-right-width: 0;
}
.numbers-grid li:nth-child(9) {
		border-bottom-width: 0;
	}
}
@media screen and (min-width: 800px) {
	.numbers-grid li:nth-child(3n) {
		border-right-width: 0;
	}
	.numbers-grid li:nth-last-child(1),
	.numbers-grid li:nth-last-child(2),
	.numbers-grid li:nth-last-child(3) {
		border-bottom-width: 0;
	}
}
.number-inner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	white-space: normal;
}
.number-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.numbers-grid li .number-bg {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 50% 50%;
}
.numbers-grid li:nth-child(1) .number-bg {
	background-image: url("../img/backgrounds/number-1.svg");
}
.numbers-grid li:nth-child(2) .number-bg {
	background-image: url("../img/backgrounds/number-2.svg");
}
.numbers-grid li:nth-child(3) .number-bg {
	background-image: url("../img/backgrounds/number-3.svg");
}
.numbers-grid li:nth-child(4) .number-bg {
	background-image: url("../img/backgrounds/number-4.svg");
}
.numbers-grid li:nth-child(5) .number-bg {
	background-image: url("../img/backgrounds/number-5.svg");
}
.numbers-grid li:nth-child(6) .number-bg {
	background-image: url("../img/backgrounds/number-6.svg");
}
.numbers-grid li:nth-child(7) .number-bg {
	background-image: url("../img/backgrounds/number-7.svg");
}
.numbers-grid li:nth-child(8) .number-bg {
	background-image: url("../img/backgrounds/number-8.svg");
}
.numbers-grid li:nth-child(9) .number-bg {
	background-image: url("../img/backgrounds/number-9.svg");
}

/*--------------------------------------------------------------
# Was Freshjobs ausmacht
--------------------------------------------------------------*/
.usp-titel h2 {
	text-align: center;
	color: #46A2DA;
}
.usp-wrap {
	display: flex;
	justify-content: center;
}
.unique-selling-points {
	display: flex;
	flex-wrap: wrap;
	z-index:1;
}
.usp-item {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}
.usp-item h3 {
	font-weight: 600;
	margin-bottom: 20px;
}

.usp-item p {
	max-width: 40ch;
}
@media screen and (min-width: 992px) {
	.unique-selling-points {
		flex-wrap: nowrap;
		max-width: 1400px;
		margin: 0 auto;
	}
	.usp-item {
		flex: 1 1 0;
		margin: 15px;
	}
}

/*--------------------------------------------------------------
# Gründe für Beide
--------------------------------------------------------------*/
.icon-circle-wrap {
	position: relative;
	z-index: 4;
}

.icon-circle-wrap p {
	color: #46A2DA;
	font-size: calc(1.2rem + 0.4vw);
	font-weight: 600;
	line-height: 1.25;
}

@media screen and (min-width: 992px) {
	.icon-circle-wrap {
		max-width: 1400px;
		margin: 0 auto;
	}
}
.icon-centered {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	z-index: 1;
	position: relative;
}
.icon-space-around-inner-right, .icon-space-around-inner-left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}
.icon-circle-wrap .icon-space-around-inner-right:nth-child(2){
	flex-direction: column-reverse;
}
.icon-circle-wrap img {
	width: auto;
	height: 200px;
	max-width: 100%;
	margin: auto;
}
.icon-circle-wrap p {
	margin:0 auto;
	max-width: 40ch;
}

@media screen and (min-width: 769px) {
	.icon-circle-wrap {
		padding: 0 64px;
		margin-top: -64px;
	}
	.icon-space-around {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 40px 0;
	}
	.icon-space-around-inner-right {
		flex-direction: row;
		align-items: center;
		width: 50%;
		margin-left: 30px;
		justify-content: flex-end;
		text-align: right;
	}
	.icon-space-around-inner-left {
		flex-direction: row;
		align-items: center;
		width: 50%;
		margin-right: 30px;
		justify-content: flex-start;
		text-align:left;
	}
	.icon-space-around-inner:first-child p {
		text-align: left;
	}
	.icon-space-around-inner:nth-child(2) p {
		text-align: right;
	}
	.icon-circle-wrap .icon-space-around-inner-right:nth-child(2){
		flex-direction: row;
	}
	.icon-circle-wrap .icon-centered:last-child {
		flex-direction: column-reverse;
	}
}
@media screen and (min-width: 992px) {
	.icon-space-around-inner-left, .icon-space-around-inner-right {
		width: 40%;
	}

	.icon-circle-wrap {
		margin-top: -64px;
		padding: 0px 150px;
	}
}
/*--------------------------------------------------------------
# Newsletter Form
--------------------------------------------------------------*/
.newsletter {
	padding: 16px;
}
@media screen and (min-width: 992px) {
	.newsletter {
		padding: 64px;
		max-width: 1400px;
		margin: 0 auto;
	}
}

.newsletter-box {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #F6FAE9;
	box-shadow: 5px 5px 30px rgba(0, 163, 223, 0.2), -5px -5px 30px rgba(255, 255, 255, 0.5), inset 5px 5px 100px rgba(255, 255, 255, 0.5);
	border-radius: 8px;
	padding: 32px;
	margin: 30px 0;
}

.newsletter-form-wrap {
	width: 100%;
}

.newsletter-form-wrap h4 {
	color: #A3C840;
	text-align: center;
	margin-bottom:0;
}
.newsletter-form-wrap form {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}
.newsletter-form-wrap form input[type=email] {
	background: #FFFFFF;
	border: 1px solid #93B439;
	border-radius: 8px;
	height: 50px;
	color: #A3C840;
	font-weight: 600;
	font-size: 1rem;
	padding: 0 15px;
	margin: 0 0 15px 0;
	width: 100%;
	font-family: "Utily", "Arial", sans-serif;
}
.newsletter-form-wrap form input[type=submit] {
	background: #F6FAE9;
	border: 1px solid #93B439;
	border-radius: 8px;
	color: #A3C840;
	font-weight: 600;
	font-size: 1rem;
	padding: 15px;
	margin: 0;
	font-family: "Utily", "Arial", sans-serif;
	cursor: pointer;

	&:hover {
		background: #fff;
	}
}
@media screen and (min-width: 768px) {
	.newsletter-form-wrap form {
		flex-direction: row;
	}
	.newsletter-form-wrap form input[type=email] {
		/* min-width: 450px; */
		margin: 15px 0;
		padding: 5px 15px;
	}
	.newsletter-form-wrap form input[type=submit] {
		margin: 15px;
	}
}
@media screen and (min-width: 992px) {
	.newsletter-box {
		margin: 0 10%;
		padding: 64px;
	}
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.site-footer {
	padding: 16px;
	font-size: calc(1rem + 0.1vw);
}

@media screen and (min-width: 992px) {
	.site-footer {
		padding: 64px;
	}
	.site-footer .wrapper-centered {
		max-width: 1400px;
		margin: 0 auto;
	}
}


@media screen and (min-width: 1400px) {
	.site-footer {
		font-size: 1.125rem;
	}
}
.site-footer ul {
	list-style: none;
	padding-left: 0;
}
.site-footer a {
	display: inline-block;
	position: relative;
	padding: 0.3125rem 0;
	color: #3F92C4;
	font-weight: 600;
	transition: all 240ms ease-in-out;
}
.site-footer a:hover {
	color: #333;
}
.site-footer a:before {
	content: " ";
	position: absolute;
	bottom: 0.1875rem;
	left: 0;
	height: 0.125rem;
	width: 0;
	background: rgba(0,163,223,0.25);
	transition: all 240ms ease-in-out;
}
.site-footer a:hover:before {
	width: 100%;
}
.footer-main, .footer-logo, .footer-social, .copyright{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
/* Footer Logo */
.footer-logo {
	margin-top: 40px;
	margin-bottom: 40px;
	position: relative;
	z-index: 1;
}

/* Footer Main */
.footer-title {
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	letter-spacing: 0.046875rem;
}
.footer-main .footer-item {
	width: 50%;
	z-index: 1;
}

/* Footer Social */
.footer-social .footer-title {
	text-align: center;
	margin-top: 50px;
}
.footer-social ul {
	display: flex;
}
.footer-social ul li {
	padding: 0 15px;
}

/* Copyright */
.copyright p {
	font-weight: 600;
	color: #46A2DA;
}

@media screen and (min-width: 992px) {
	.footer-main, .footer-social, .copyright{
		margin: 0 10%;
	}
	.footer-main .footer-item {
		width: 25%;
	}
}
